/* src/assets/styles/global.css */

body {
    margin: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
  
  .main-content {
    min-height: calc(100vh - 64px - 56px); /* Adjust based on header and footer heights */
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  